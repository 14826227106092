import useSWR from "swr";
import axios from "@/lib/axios";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import cookieCutter from "cookie-cutter";
import useLoader from "@/hooks/loader";
import {
    convertToFormData,
    deregisterAllServiceWorkers,
    userRole,
} from "@/utilities";
import { useAnalytics } from "@/hooks/analytics";
import { analyticsEvents, guestRoutes } from "@/constants";
import { useDispatch } from "react-redux";
import { authUser } from "@/services/slices/usersSlice";

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
    const loader = useLoader();
    const router = useRouter();
    const dispatch = useDispatch();
    const { analytics } = useAnalytics();
    let token = null;
    let destination_path = null;

    // Flag to execute tracking only when the login/register function is called the first time
    const [firstLogin, setFirstLogin] = useState(false);
    const [firstLogout, setFirstLogout] = useState(false);
    const [firstRegister, setFirstRegister] = useState(false);

    const csrf = () => axios.get("/sanctum/csrf-cookie");

    const {
        data: user,
        error,
        revalidate,
    } = useSWR(
        "/api/user",
        async () => {
            await csrf();

            let getToken = false;
            if (user && user.token) {
                token = user.token;
            } else if (!user) {
                getToken = true;
            }

            return axios
                .get(`/api/user?token=${getToken ? "true" : "false"}`)
                .then((res) => {
                    if (!res.data.allowedOnWebApp) {
                        window.location = process.env.NEXT_PUBLIC_BACKEND_URL;
                    }

                    if (getToken) {
                        token = res.data.token;
                        cookieCutter.set("token", token, {
                            path: "/",
                        });
                    }

                    if (res.data.force_reset_password) {
                        router.push("/force-reset-password");
                    }

                    dispatch(authUser(res.data));
                    return res.data;
                })
                .catch((error) => {
                    if (error.response?.status !== 409) {
                        if (error.response?.status === 401) {
                            token = null;
                            cookieCutter.set("token", null, {
                                expires: new Date(0),
                                path: "/",
                            });

                            if (
                                middleware === "auth" &&
                                !router.pathname.includes("/login") &&
                                !router.pathname.includes("/share")
                            ) {
                                window.location.pathname = "/login";
                            }
                        }

                        throw error;
                    }

                    router.push("/verify-email");
                });
        },
        {
            revalidateOnFocus: false,
            refreshInterval: 1800000, // 30 minutes
            refreshWhenHidden: true,
        }
    );

    const organization = user?.organization;

    const register = async ({ setErrors, ...props }) => {
        await csrf();

        setErrors([]);
        loader.start();
        axios
            .post("/register", convertToFormData(props))
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    setFirstRegister(true);
                }
                revalidate();
            })
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => loader.done());
    };

    const login = async ({ setErrors, setStatus, ...props }) => {
        await csrf();
        setErrors([]);
        setStatus(null);
        loader.start();

        axios
            .post("/login", props.payload)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    setFirstLogin(true);
                    setFirstLogout(false);
                }
                revalidate();
            })
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => {
                loader.done();
            });
    };

    const googleLogin = async ({ setErrors, setStatus, ...props }) => {
        await csrf();
        setErrors([]);
        setStatus(null);
        loader.start();

        axios
            .post("/google-login", props)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    setFirstLogin(true);
                    setFirstLogout(false);
                }
                revalidate();
            })
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => loader.done());
    };

    const forgotPassword = async ({ setErrors, setStatus, email }) => {
        await csrf();

        setErrors([]);
        setStatus(null);
        loader.start();

        axios
            .post("/forgot-password", { email })
            .then((response) => setStatus(response?.data.status))
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => loader.done());
    };

    const resetPassword = async ({ setErrors, setStatus, ...props }) => {
        await csrf();

        setErrors([]);
        setStatus(null);
        loader.start();

        axios
            .post("/reset-password", { token: router.query.token, ...props })
            .then((response) =>
                router.push("/login?reset=" + btoa(response?.data.status))
            )
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => loader.done());
    };

    const forceResetPassword = async ({ setErrors, setStatus, ...props }) => {
        await csrf();

        setErrors([]);
        setStatus(null);
        loader.start();

        axios
            .post("/force-reset-password", {
                ...props,
            })
            .then(() => router.push("/company/dashboard"))
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => loader.done());
    };

    const forcedUpdateDetails = async ({ setErrors, setStatus, ...props }) => {
        await csrf();

        setErrors([]);
        setStatus(null);
        loader.start();

        axios
            .post("/force-update-details", {
                ...props,
            })
            .then(() => {
                if (user.is_onboarding_completed) {
                    router.push("/engineer/interviews");
                } else {
                    router.push(
                        `/engineer/onboarding?stage=${user?.onboarding_step}`
                    );
                }
            })
            .catch((error) => {
                if (error.response?.status !== 422) throw error;

                setErrors(Object.values(error.response?.data.errors).flat());
            })
            .finally(() => loader.done());
    };

    const resendEmailVerification = ({ setStatus }) => {
        loader.start();

        axios
            .post("/email/verification-notification")
            .then((response) => setStatus(response?.data.status))
            .finally(() => loader.done());
    };

    const logout = async () => {
        if (!error) {
            loader.start();
            await axios
                .post("/logout")
                .then(async (response) => {
                    if (response.status === 200 || response.status === 204) {
                        setFirstLogout(true);
                    }

                    await revalidate();

                    token = null;
                    cookieCutter.set("token", null, {
                        expires: new Date(0),
                        path: "/",
                    });

                    dispatch(authUser(undefined));
                    localStorage.clear()
                    deregisterAllServiceWorkers();
                    window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/login`;
                })
                .finally(() => loader.done());
        }
    };

    useEffect(async () => {
        destination_path = cookieCutter.get("destination_path");

        token = cookieCutter.get("token");
        if (
            !token &&
            middleware !== "guest" &&
            !guestRoutes.includes(router?.pathname)
        ) {
            await router.push("/login");
            cookieCutter.set(
                "destination_path",
                router?.asPath ?? window.location.pathname,
                {
                    path: "/",
                }
            );
        }
    }, []);

    // tracking effect function runs only when user logs in
    useEffect(async () => {
        if (user) {
            let roles = [];
            user.roles.forEach((e) => {
                roles.push(e.name);
            });
            const urlParams = new URLSearchParams(window.location.search);
            const share_code = urlParams.get("share_code");
            const referral_code = urlParams.get("referral_code");

            let traits = {
                name: user.name,
                email: user.email,
                id: user.id,
                roles: roles,
                share_code: share_code,
                referral_code: referral_code,
                company: {
                    id: user.organization?.id,
                    name: user.organization?.name,
                },
                // HotJar does not support
                role_list: roles?.join(","),
                company_id: user.organization?.id,
                company_name: user.organization?.name,
            };
            let options = {
                Intercom: {
                    user_hash: user.intercom_user_hash,
                },
            };
            await analytics.identify(user.id, traits, options);

            if (firstLogin) {
                await analytics.track(analyticsEvents.USER_LOGGED_IN, traits);
                setFirstLogin(false);
            }
        }
    }, [user, firstLogin]);

    useEffect(async () => {
        if (user) {
            await analytics.page(document.title);
        }
    }, [user]);

    useEffect(async () => {
        if (user && firstLogout) {
            let traits = {
                name: user.name,
                email: user.email,
                id: user.id,
                company: {
                    id: user.organization?.id,
                    name: user.organization?.name,
                },
            };
            await analytics.track(analyticsEvents.USER_LOGGED_OUT, traits);
            await analytics.reset();
            setFirstLogout(false);
        }
    }, [user, firstLogout]);

    useEffect(async () => {
        if (user && firstRegister) {
            let roles = [];
            user.roles.forEach((role) => roles.push(role.name));

            const urlParams = new URLSearchParams(window.location.search);
            const share_code = urlParams.get("share_code");
            const referral_code = urlParams.get("referral_code");
            const type = urlParams.get("type");

            let traits = {
                name: user.name,
                email: user.email,
                id: user.id,
                roles: roles,
                share_code: share_code,
                referral_code: referral_code,
                type: type,
                company: {
                    id: user.organization?.id,
                    name: user.organization?.name,
                },
                // HotJar does not support
                role_list: roles?.join(","),
                company_id: user.organization?.id,
                company_name: user.organization?.name,
            };
            let options = {
                Intercom: {
                    user_hash: user.intercom_user_hash,
                },
            };
            await analytics.identify(user.id, traits, options);
            await analytics.track(analyticsEvents.USER_SIGNUP, traits);
            setFirstRegister(false);
        }
    }, [user, firstRegister]);

    useEffect(async () => {
        const { userIsCompany, userIsEngineer } = userRole(user);

        if (middleware === "guest" && redirectIfAuthenticated && user) {
            if (user.missing_details) {
                await router.push("/force-add-details");
            } else if (user.force_reset_password) {
                await router.push("/force-reset-password");
            } else {
                if (userIsCompany) {
                    await router.push(redirectIfAuthenticated.company);
                } else if (userIsEngineer) {
                    if (user.is_onboarding_completed) {
                        await router.push("/engineer/interviews");
                    } else {
                        await router.push(
                            `/engineer/onboarding?stage=${user?.onboarding_step}`
                        );
                    }
                } else {
                    await router.push(redirectIfAuthenticated.fallback);
                }
            }
        }

        if (middleware === "auth") {
            if (user) {
                if (destination_path) {
                    await router.push(destination_path);
                    cookieCutter.set("destination_path", null, {
                        expires: new Date(0),
                        path: "/",
                    });
                }
                if (user.missing_details) {
                    await router.push("/force-add-details");
                } else if (user.force_reset_password) {
                    await router.push("/force-reset-password");
                } else {
                    const moduleName = router.pathname.split("/")[1];
                    const openModules = ["force-reset-password"];

                    if (!openModules.includes(moduleName)) {
                        const companyModule = "company";
                        const engineerModule = "engineer";

                        if (companyModule === moduleName && !userIsCompany) {
                            if (userIsEngineer) {
                                await router.push("/engineer/profile");
                            } else {
                                await logout();
                            }
                        }

                        if (engineerModule === moduleName) {
                            if (!userIsEngineer) {
                                if (userIsCompany) {
                                    await router.push("/company/dashboard");
                                } else {
                                    await logout();
                                }
                            } else {
                                if (!user.is_onboarding_completed) {
                                    await router.push(
                                        `/engineer/onboarding?stage=${user?.onboarding_step}`
                                    );
                                } else {
                                    if (
                                        router.pathname.split("/")[2] ===
                                        "onboarding"
                                    ) {
                                        await router.push(
                                            "/engineer/interviews?is_onboarded=true"
                                        );
                                    } else if (
                                        router.pathname.split("/")[2] ===
                                            "openings" &&
                                        engineerModule === moduleName
                                    ) {
                                        await router.push("/404"); //disabling openings tab temporarily
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                token = cookieCutter.get("token");
                if (router?.pathname !== "/404" && !token) {
                    cookieCutter.set(
                        "destination_path",
                        router?.asPath ?? window.location.pathname,
                        {
                            path: "/",
                        }
                    );
                }
            }

            if (error) {
                await logout();
            }
        }
    }, [user, error]);

    return {
        user,
        organization,
        register,
        login,
        forgotPassword,
        resetPassword,
        forceResetPassword,
        resendEmailVerification,
        logout,
        revalidate,
        googleLogin,
        forcedUpdateDetails,
    };
};
